var generic = generic || {};

function getSelectedCountryName() {
  var countryName = 'United States';
  var localeCookie = generic.cookie('LOCALE');

  $('a.country').each(function () {
    var country;
    var countryParams = $(this).attr('href').split('/');

    if (countryParams.length > 3) {
      countryParams = countryParams.slice(-2);
      country = '/' + countryParams.join('/');
      $(this).attr('href', country);
    }
    if (localeCookie === $(this).attr('data-pg-locale')) {
      countryName = $(this).text();
      return;
    }
  });

  return countryName;
}

var $countrySelector = $('.footer-country-selector');
var $selectedCountry = $('.country.selected');

$selectedCountry.text(getSelectedCountryName());

$countrySelector.on('click', function() {
  $(this).toggleClass('open');
});

$countrySelector.on('blur', function() {
  setTimeout(() => $(this).removeClass('open'), 500);
});
